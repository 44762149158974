import { ValidationProvider, ValidationObserver } from 'vee-validate'
export default {
  name: 'SearchInvoice',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      invoice: null,
    }
  },
}
